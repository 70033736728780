.loginContainer {
    width: 100%;
    height: 100vh;
    display: flex; justify-content: center;
    margin-top: 96px;
}

.loginDiv {
    width: 420px;
    height: 520px;
    background-color: #fbfbfb;
    margin-top: 32px;
    padding: 42px 32px;
    display: flex; align-items: center; flex-flow: column nowrap;
    border-radius: 5px;
}

.loginDiv img {
    width: 137px;
    margin-bottom: 24px;
}

.title {
    color: #0B0A0A;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    margin-bottom: 48px;
}

.info {
    display: flex;
    flex-flow: column nowrap; 
    align-items: left;
    width: 100%;
}

.info input {
    border-radius: 3px;
    border: 1px solid #262D6B;
    padding: 14px 12px;
    outline: none;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
}

.info input::placeholder {
    color: #262D6B;
}

.info p {
    margin-bottom: 12px;
    font-weight: bold;
}

.btn {
    display: flex; justify-content: center; align-items: center;
    padding: 16px;
    outline: none;
    border: none;
    color: #F1F2F6;
    font-weight: bold;
    background-color: #262D6B;
    border-radius: 5px;
    font-size: 18px;
    margin-top: 50px;
    cursor: pointer;
}

.a {
    width: 100%; 
    display: flex; justify-content: center;
    margin-top: 12px;
    cursor: pointer;
}

.redirect {
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
}

.headerMilla {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;
    width: 100%;
    height: 10vh;
    z-index: 1000;
    background-color: #FBFBFB;
    flex-flow: row;
    border-bottom: 4px #E8E8E8 solid;
    position: fixed;
}

.img-nav{
    height: 32px;
    border: none;
}

.search {
    display: flex;
    justify-content: space-between;
    width: 864px;
    background-color: #CCCCCC;
    border-radius: 100px;
    padding: 8px 16px;
}

.caixa {    
    border: none;
    background-color: #CCCCCC;
    height: 25px;
    width: 92%;
}

.caixa {
    outline: none;
}

.caixa:active{
    outline:none;
}
.registerContainer {
    width: 100%;
    height: 100vh;
    display: flex; justify-content: center; align-items: center;
}

.registerDiv {
    width: 900px;
    height: 520px;
    background-color: #fbfbfb;
    padding: 42px 48px;
    display: flex; align-items: center; flex-flow: column nowrap;
    border-radius: 6px;
    z-index: 2;
    position: relative;
}

.logo {
    width: 137px;
    margin-bottom: 24px;
}

.title {
    color: #0B0A0A;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.info {
    display: flex;
    flex-flow: column nowrap; 
    align-items: left;
    width: 100%;
}

.info input {
    border-radius: 3px;
    border: 1px solid #262D6B;
    padding: 14px 12px;
    outline: none;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
}

.info input::placeholder {
    color: #262D6B;
}

.info p {
    margin-bottom: 12px;
    font-weight: bold;
}

.a {
    width: 100%; 
    display: flex; justify-content: center;
    margin-top: 12px;
    cursor: pointer;
}

.redirector {
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    color: black;
    position: absolute; top: 0; left: 0;
    margin: 25px 30px;
    font-weight: bold;
}

.header {
    display: flex;
    justify-content: center; flex-flow: column; align-items: center;
    width: 100%;
    margin-bottom: 40px;
}

.steps {
    width: 120px;
    height: 20px;
}
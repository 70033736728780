@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter'
}

.category {
    color: #262D6B;
    background-color: #FBFBFB;
    font-size: 20px;
    font-weight: 700;
    text-transform: lowercase;
    border: #262D6B 2px solid;
    border-radius: 8px;
    padding: 4px 16px;
    cursor: pointer;
}
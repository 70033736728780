.home {
    display: flex; flex-flow: row nowrap;
    width: 100%;
}


.home aside {
    padding: 20px 20px 0px 0px;
    width: fit-content;
    width: 180px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex; flex-flow: column nowrap; gap: 10px;
    position: fixed;
}

.home .left .category {
    border-radius: 10px;
}

.home aside::-webkit-scrollbar {
    width: 0px
  }

.content aside .category {
    width: 180px;
    border-radius: 20px;
}

.content {
    display: flex; flex-flow: row nowrap;
    width: 100%;
    margin-top: 13vh;
}

.buttonAa {
    display: flex; justify-content: center; align-items: center;
    padding: 16px 6px;
    outline: none;
    border: none;
    color: #F1F2F6;
    font-weight: bold;
    background-color: #262D6B;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    letter-spacing: 1.1px;
    width: 95%;
}

.abc {
    background-color: #fbfbfb;
    color: #262D6B;
    border: 1px solid #262D6B;
    width: 95%;
}

.recom {
    background-color: #262D6B;
    width: 95%;
    height: fit-content;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 10px;
}

.artigos {
    width: calc(100% - 360px);
    background-color: black;
}

.right {
    display: flex; flex-flow: row nowrap; justify-content: center; align-items: center;
    right: 0;
}

.left {
    left: 0;
    margin-left: 20px;
}

.vert {
    height: 95%;
    rotate: 90deg;
}

.login {
    background-color: #F1F2F6;
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    display: flex; justify-content: center;
}

.bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.all {
    z-index: 2;
}
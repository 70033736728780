@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter';
}

.row {
  display: flex; flex-flow: row nowrap; gap: 10px;
}

.column {
  display: flex; flex-flow: column nowrap; gap: 10px;
}

.mankey {
  width: 100%;
  display: flex; justify-content: left;
}

.input, .input2, .option {
  border-radius: 3px;
  border: 1px solid #262D6B;
  padding: 14px 12px;
  outline: none;
  width: 400px;
  font-size: 16px;
  font-weight: bold;
}

.input::placeholder {
  color: #262D6B;
}

.mankey2 {
  font-size: 12px; width: 100%;
  display: flex; justify-content: space-between;
  margin-top: 10px;
}

.mankey2 .column .row2 img {
  width: 14px;
  height: 14px;
}

.row2 {
  display: flex; justify-content: left; align-items: center;
}

.verticalRow {
  border: 1px solid rgba(0, 0, 0, 0.616);
  width: 1px;
  height: 160px;
  margin-top: 25px;
}

.square {
  display: flex; flex-flow: column nowrap; align-items: center; justify-content: center;
}

.btnmacaco {
  display: flex; justify-content: center; align-items: center;
  padding: 16px;
  outline: none;
  border: 3px solid #262D6B;
  background-color: transparent;
  color: #F1F2F6;
  font-weight: bold;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 50px;
  cursor: pointer;
  width: 300px;
  letter-spacing: 1.1px;
  color: #262D6B;
  height: 60px;
  margin: 15px 30px;
  transition: .2s ease-in-out;
}

.btnmacaco:hover {
  color: #F1F2F6;
  background-color: #262D6B;
}

a {
  text-decoration: none;
}

.input2 {
  color: #262D6B;
  position: relative;
  display: flex; align-items: center;
  cursor: pointer;
}

.aaa {
  margin-bottom: 20px;
}

.arrow {
  width: 20px;
  height: 10px;
  position: absolute; right: 0;
  margin-right: 15px;
  transition: .2s ease-in-out;
}

.option {
  position: absolute;
  background-color: #262D6B;
  z-index: 5;
  padding: 5px 0px;
}

.node {
  position: relative;
}

.opcao {
  color: #F1F2F6;
  cursor: pointer;
  height: 35px;
  margin: 0;
  padding: 10px;
}

.terrorDaZN {
  display: grid;
  grid-template-columns: repeat(3, auto );
  gap: 16px;
  width: 70%;
  height: 10%;
}

.category {
  color: #262D6B;
  background-color: #FBFBFB;
  font-size: 20px;
  font-weight: 700;
  text-transform: lowercase;
  border: #262D6B 2px solid;
  border-radius: 8px;
  padding: 4px 16px;
  cursor: pointer;
}


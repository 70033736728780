.button {
    display: flex; justify-content: center; align-items: center;
    padding: 16px;
    outline: none;
    border: none;
    color: #F1F2F6;
    font-weight: bold;
    background-color: #262D6B;
    border-radius: 5px;
    font-size: 18px;
    margin-top: 50px;
    cursor: pointer;
    width: 200px;
    letter-spacing: 1.1px;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 15px 40px;
    height: 40px;
}
